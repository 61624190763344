import { useState, useEffect, Fragment } from "react";
import SideBar from "./components/SideBar";
import TopBar from "../TopBar";
import { Transition } from "@headlessui/react";
import Footer from "../../components/Footer";
import {
  XMarkIcon,
  PencilSquareIcon,
  CheckIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/outline";

import axios from "axios";

export default function Layout({ children }) {
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const [listOfUnapprovedJobs, setListOfUnapprovedJobs] = useState([]);

  function handleResize() {
    if (window.innerWidth <= 640) {
      setShowNav(false);
      setIsMobile(true);
    } else {
      setShowNav(true);
      setIsMobile(false);
    }
  }

  useEffect(() => {
    if (typeof window != undefined) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/jobs/allNotApproved", {
        headers: { accessToken: localStorage.getItem("accessToken") },
      })
      .then((response) => {
        setListOfUnapprovedJobs(response.data);
      });
  }, []);

  const handleApprove = async (profileId) => {
    try {
      const response = await axios.put(
        `https://api.askamarlabor.com/serverapi/jobs/updateApproveJob/${profileId}`,
        null, // Use null for the request body if you don't need to send data
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        axios
          .get("https://api.askamarlabor.com/serverapi/jobs/allNotApproved", {
            headers: { accessToken: localStorage.getItem("accessToken") },
          })
          .then((response) => {
            setListOfUnapprovedJobs(response.data);
          });
      } else {
        // Handle errors if needed.
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error:", error);
    }
  };

  const handleReject = async (profileId) => {
    try {
      const response = await axios.put(
        `https://api.askamarlabor.com/serverapi/jobs/updateRejectJob/${profileId}`,
        null, // Use null for the request body if you don't need to send data
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        axios
          .get("https://api.askamarlabor.com/serverapi/jobs/allNotApproved", {
            headers: { accessToken: localStorage.getItem("accessToken") },
          })
          .then((response) => {
            setListOfUnapprovedJobs(response.data);
          });
      } else {
        // Handle errors if needed.
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error:", error);
    }
  };

  return (
    <>
      <TopBar showNav={showNav} setShowNav={setShowNav} />
      <Transition
        as={Fragment}
        show={showNav}
        enter="transform transition duration-[400ms]"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-[400ms] transition ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <SideBar showNav={showNav} />
      </Transition>
      <main
        className={`pt-16 transition-all duration-[400ms] ${
          showNav && !isMobile ? "pl-56" : ""
        }`}
      >
        <div className="px-4 md:px-16">
          {" "}
          <>
            <p className="text-gray-700 text-3xl mb-16 font-bold mt-10">
              Approve Job
            </p>

            <div className="grid gap-5 bg-white  mb-10  p-9 rounded-lg">
              {listOfUnapprovedJobs.map((value) => {
                return (
                  <div className="flex justify-between border p-6 rounded-md shadow-lg">
                    <div className="grid">
                      <p className="text-black text-lg font-semibold">
                        {value.Title}
                      </p>
                      <p className="">Posted by: user {value.User.username}</p>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={() => handleApprove(value.id)}
                        className="text-white bg-green-400 hover:border-green-400 hover:border hover:text-green-400 hover:bg-white p-2 px-4 mr-5 rounded-md"
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => handleReject(value.id)}
                        className="text-white bg-red-400 hover:border-red-400 hover:border hover:text-red-400 hover:bg-white p-2 px-4 rounded-md"
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      </main>
      <Footer />
    </>
  );
}
