import { useState, useEffect, Fragment } from "react";
import SideBar from "./components/SideBar";
import TopBar from "../TopBar";
import { Transition } from "@headlessui/react";
import Footer from "../../components/Footer";
import { XMarkIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

export default function Layout({ children }) {
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  function handleResize() {
    if (window.innerWidth <= 640) {
      setShowNav(false);
      setIsMobile(true);
    } else {
      setShowNav(true);
      setIsMobile(false);
    }
  }

  useEffect(() => {
    if (typeof window != undefined) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [formData, setFormData] = useState({
    Title: "",
    Description: "",
    experience: null,
    gender: "",
    deadline: "",
    minSalary: null,
    maxSalary: null,
    status: "Pending",
    salaryType: "",

    city: "",
    localArea: "",
    profession: "",
    jobtypeid: "",
  });

  const [cityOptions, setCityOptions] = useState([]);
  const [localAreaOptions, setLocalAreaOptions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [jobtypeOptions, setJobTypeOptions] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/cities")
      .then((response) => {
        setCityOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching city data:", error);
      });

    axios
      .get("https://api.askamarlabor.com/serverapi/professions")
      .then((response) => {
        setProfessionOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profession data:", error);
      });

    axios
      .get("https://api.askamarlabor.com/serverapi/jobtypes")
      .then((response) => {
        setJobTypeOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profession data:", error);
      });
  }, []);

  const [selectedCity, setSelectedCity] = useState(formData.city);
  const [selectedLocalArea, setSelectedLocalArea] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");

  useEffect(() => {
    if (selectedCity) {
      axios
        .get(
          `https://api.askamarlabor.com/serverapi/localareas/bycity?city=${selectedCity}`
        )
        .then((response) => {
          setLocalAreaOptions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching local area data by city:", error);
        });
    }
  }, [selectedCity]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === "profileImage" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);

    // Create a FormData object to send the job data
    const formDataForUpdate = new FormData();

    // Append the job details to the FormData object
    formDataForUpdate.append("Title", formData.Title);
    formDataForUpdate.append("Description", formData.Description);
    formDataForUpdate.append("experience", formData.experience);
    formDataForUpdate.append("gender", formData.gender);
    formDataForUpdate.append("deadline", formData.deadline);
    formDataForUpdate.append("minSalary", formData.minSalary);
    formDataForUpdate.append("maxSalary", formData.maxSalary);
    formDataForUpdate.append("salaryType", formData.salaryType);
    formDataForUpdate.append("status", formData.status);

    // Append the location and profession data
    formDataForUpdate.append("CityId", formData.city);
    formDataForUpdate.append("localareaid", formData.localArea);
    formDataForUpdate.append("ProfessionId", formData.profession);

    // Append the job type data
    formDataForUpdate.append("jobtypeid", formData.jobtypeid);

    try {
      console.log("Sending Request with FormData:", formDataForUpdate);

      // Send a POST request to create a new job with the specified FormData
      const response = await axios.post(
        `https://api.askamarlabor.com/serverapi/jobs/`,
        formDataForUpdate,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        // Handle success, e.g., display a success message to the user
        navigate(`/myjobs`); // Redirect to the updated profile page
      } else {
        console.error(
          "Error creating job. Server responded with status:",
          response.status
        );
        // Handle the specific server response status code as needed
      }
    } catch (error) {
      console.error("Error creating job:", error);

      if (error.response) {
        // The request was made, but the server responded with a non-2xx status code
        console.error("Server responded with status:", error.response.status);
        console.error("Server response data:", error.response.data);

        // Handle specific error cases based on the status code
        if (error.response.status === 404) {
          // Handle 404 Not Found error
        } else if (error.response.status === 500) {
          // Handle 500 Internal Server Error
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error(
          "No response received from the server. Check server availability."
        );
      } else {
        // Something else happened while setting up the request
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  return (
    <>
      <TopBar showNav={showNav} setShowNav={setShowNav} />
      <Transition
        as={Fragment}
        show={showNav}
        enter="transform transition duration-[400ms]"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-[400ms] transition ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <SideBar showNav={showNav} />
      </Transition>
      <main
        className={`pt-16 transition-all duration-[400ms] ${
          showNav && !isMobile ? "pl-56" : ""
        }`}
      >
        <div className="px-4 md:px-16">
          {" "}
          <>
            <p className="text-gray-700 text-3xl mb-16 font-bold mt-10">
              Post a job
            </p>

            <form onSubmit={handleSubmit}>
              <div className="grid lg:grid-cols-2 gap-10 bg-white mb-10  p-9 rounded-lg">
                <div className="grid col-span-full">
                  <p className="text-black font-semibold mb-4">Job Title</p>

                  <input
                    type="text"
                    name="Title"
                    value={formData.Title}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid col-span-full">
                  <p className="text-black font-semibold mb-4">
                    Job description
                  </p>

                  <textarea
                    className="textarea textarea-info h-60 bg-secondary text-black"
                    placeholder=""
                    name="Description"
                    value={formData.Description}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">
                    Required profession
                  </p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.profession}
                    name="profession"
                    onChange={(e) => {
                      setSelectedProfession(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value="">Select a profession</option>
                    {professionOptions.map((profession) => (
                      <option key={profession.id} value={profession.id}>
                        {profession.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Job type</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.jobtypeid}
                    name="jobtypeid"
                    onChange={(e) => {
                      setSelectedJobType(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value="">Select job type</option>
                    {jobtypeOptions.map((jobType) => (
                      <option key={jobType.id} value={jobType.id}>
                        {jobType.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">
                    Experience (year)
                  </p>

                  <input
                    type="number"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    placeholder=""
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Gender</p>

                  <select
                    name="gender"
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">
                    Application deadline date
                  </p>
                  <input
                    type="date"
                    name="deadline"
                    value={formData.deadline}
                    onChange={handleChange}
                    placeholder=""
                    className="input input-bordered input-info w-full bg-secondary text-black"
                    required
                  />
                </div>

                <div className="grid">
                  <p className="text-black font-semibold mb-4">City</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.city}
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                      handleChange(e); // Call the handleChange function
                    }}
                    name="city"
                    required
                  >
                    <option value="">Select a city</option>
                    {cityOptions.map((city) => (
                      <option key={city.id} value={city.id}>
                        {" "}
                        {/* Updated this line */}
                        {city.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Local Area</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    name="localArea"
                    value={formData.localArea}
                    onChange={(e) => {
                      setSelectedLocalArea(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value="">Select a local area</option>
                    {localAreaOptions.map((localArea) => (
                      <option key={localArea.id} value={localArea.id}>
                        {localArea.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Salary type</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.salaryType}
                    onChange={handleChange}
                    name="salaryType"
                    required
                  >
                    <option>Select salary type</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Hourly">Hourly</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Daily">Daily</option>
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Min. salary</p>

                  <input
                    type="number"
                    name="minSalary"
                    value={formData.minSalary}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Max. salary</p>

                  <input
                    type="number"
                    name="maxSalary"
                    value={formData.maxSalary}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-outline border-primary hover:text-primary hover:bg-white bg-primary text-white mb-10"
              >
                Post Job
              </button>
            </form>
          </>
        </div>
      </main>
      <Footer />
    </>
  );
}
