import React, { useState, useEffect, Fragment } from "react";
import SideBar from "./components/SideBar";
import TopBar from "../TopBar";
import { Transition } from "@headlessui/react";
import Footer from "../../components/Footer";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert"; // Import react-confirm-alert
import "react-confirm-alert/src/react-confirm-alert.css"; // Import react-confirm-alert styles
import { XMarkIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/outline";

function Layout({ children }) {
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [listOfCandidates, setListOfCandidates] = useState([]);
  const navigate = useNavigate();

  function handleResize() {
    if (window.innerWidth <= 640) {
      setShowNav(false);
      setIsMobile(true);
    } else {
      setShowNav(true);
      setIsMobile(false);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/profile/bybrokerid", {
        headers: { accessToken: localStorage.getItem("accessToken") },
      })
      .then((response) => {
        setListOfCandidates(response.data);
      });
  }, []);

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this candidate's profile?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => {}, // Do nothing on "No" button click
        },
      ],
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.askamarlabor.com/serverapi/profile/bybroker/${id}`,
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        toast.success("Profile deleted successfully!", {
          position: toast.POSITION.TOP_CENTER,
        });

        setTimeout(() => {
          setListOfCandidates((candidates) =>
            candidates.filter((candidate) => candidate.id !== id)
          );
        }, 2000);
      } else {
        console.error(
          "Error deleting profile. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error deleting profile:", error);
      toast.error("Error deleting profile. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <>
      <TopBar showNav={showNav} setShowNav={setShowNav} />
      <Transition
        as={Fragment}
        show={showNav}
        enter="transform transition duration-[400ms]"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-[400ms] transition ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <SideBar showNav={showNav} />
      </Transition>
      <main
        className={`pt-16 transition-all duration-[400ms] ${
          showNav && !isMobile ? "pl-56" : ""
        }`}
      >
        <div className="px-4 md:px-16">
          <>
            <p className="text-gray-700 text-3xl mb-16 font-bold mt-10">
              Job Applicants
            </p>

            <div className="grid gap-5 bg-white  mb-10  p-9 rounded-lg">
              {listOfCandidates.map((value) => {
                return (
                  <div
                    className="flex justify-between border p-6 rounded-md"
                    key={value.id}
                  >
                    <div className="grid">
                      <p className="text-black text-lg font-semibold">
                        {value.fullName}
                      </p>
                      <p className="text-primary">{value.Profession.Name}</p>
                    </div>
                    <div className="flex items-center">
                      <Link to={`/update/${value.id}`}>
                        <button>
                          <PencilIcon className="h-6 w-6 text-blue-500 font-semibold mr-3" />
                        </button>
                      </Link>

                      <button onClick={() => confirmDelete(value.id)}>
                        <XMarkIcon className="h-6 w-6 text-red-500 font-semibold" />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Layout;
