import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  let navigate = useNavigate();

  const initialValues = {
    username: "",
    UserType: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(3).max(15).required("Username is required"),
    UserType: Yup.string().required("User Type is required"),
    password: Yup.string().min(4).max(20).required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const onSubmit = (data, { setErrors }) => {
    axios
      .post("https://api.askamarlabor.com/serverapi/users", data)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // Handle the specific error when username already exists
          setErrors({
            username: "Username already exists try another username",
          });
        } else {
          // Handle other errors
          console.error("Error:", error);
        }
      });
  };

  return (
    <main className="flex flex-col items-center justify-center w-full min-h-screen px-4 md:px-20 flex-1 font-poppins">
      <div className="bg-white rounded-2xl shadow-2xl flex flex-col md:flex-row w-full md:w-2/3 max-w-4xl text-center">
        <div className="w-2/5 hidden lg:block bg-primary text-white rounded-tl-2xl rounded-bl-2xl py-32 px-12">
          <h1 className="text-3xl font-poppins font-bold mb-2">
            Already have an account?
          </h1>
          <div className="border-2 w-10 border-white inline-block mb-2"></div>
          <p className="mb-10">Login with your account.</p>
          <a
            href="/login"
            className="border-2 border-white rounded-full px-12 py-2 inline-block font-semibold hover:bg-white hover:text-primary"
          >
            Login
          </a>
        </div>
        <div className="w-full lg:w-3/5 p-5 text-black">
          <div className="text-left font-bold">
            <span className="text-primary">ASKAMAR</span>Labor
          </div>
          <div className="py-10">
            <h2 className="text-3xl font-bold text-primary mb-4">Sign Up</h2>
            <div className="border-2 w-10 border-primary inline-block mb-8"></div>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              <Form>
                <div className="flex flex-col items-center">
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                  <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                    <Field
                      type="text"
                      name="username"
                      placeholder="Username"
                      className="bg-white outline-none text-sm flex-1 ml-2"
                    />
                  </div>
                  <ErrorMessage
                    name="UserType"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                  <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                    <Field
                      as="select"
                      name="UserType"
                      className="bg-white outline-none text-sm flex-1 ml-2"
                    >
                      <option value="" disabled>
                        User Type
                      </option>
                      <option value="Employee">Employee</option>
                      <option value="Employer">Employer</option>
                      <option value="Broker">Broker</option>
                    </Field>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                  <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="bg-white outline-none text-sm flex-1 ml-2"
                    />
                  </div>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                  <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className="bg-white outline-none text-sm flex-1 ml-2"
                    />
                  </div>
                  <button
                    type="submit"
                    className="border-2 border-primary text-primary rounded-full px-6 md:px-12 py-2 inline-block font-semibold hover:bg-primary hover:text-white"
                  >
                    Register
                  </button>
                  <p className="mb-0 mt-2 pt-1 text-sm lg:hidden">
                    Already have an account?{" "}
                    <a
                      href="/login"
                      className="text-primary transition duration-150 ease-in-out hover:text-secondary focus:text-danger-600 active:text-danger-700"
                    >
                      Login
                    </a>
                  </p>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Signup;
