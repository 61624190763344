import { useState, useEffect, Fragment } from "react";
import SideBar from "./components/SideBar";
import TopBar from "../TopBar";
import { Transition } from "@headlessui/react";
import Footer from "../../components/Footer";
import { CiLocationOn } from "react-icons/ci";
import { BsBriefcaseFill } from "react-icons/bs";
import { XMarkIcon } from "@heroicons/react/24/solid";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert"; // Import react-confirm-alert
import "react-confirm-alert/src/react-confirm-alert.css";

export default function Layout({ children }) {
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const [listOfApplied, setListOfApplied] = useState([]);

  function handleResize() {
    if (window.innerWidth <= 640) {
      setShowNav(false);
      setIsMobile(true);
    } else {
      setShowNav(true);
      setIsMobile(false);
    }
  }

  useEffect(() => {
    if (typeof window != undefined) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://api.askamarlabor.com/serverapi/appliedjobs/userAppliedJobs",
        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        setListOfApplied(response.data);
      });
  }, []);

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this Application?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => {}, // Do nothing on "No" button click
        },
      ],
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.askamarlabor.com/serverapi/appliedjobs/byEmployee/${id}`,
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        toast.success("application deleted successfully!", {
          position: toast.POSITION.TOP_CENTER,
        });

        setTimeout(() => {
          setListOfApplied((applications) =>
            applications.filter((application) => application.id !== id)
          );
        }, 2000);
      } else {
        console.error(
          "Error deleting application. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error deleting application:", error);
      toast.error("Error deleting application. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <>
      <TopBar showNav={showNav} setShowNav={setShowNav} />
      <Transition
        as={Fragment}
        show={showNav}
        enter="transform transition duration-[400ms]"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-[400ms] transition ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <SideBar showNav={showNav} />
      </Transition>
      <main
        className={`pt-16 transition-all duration-[400ms] ${
          showNav && !isMobile ? "pl-56" : ""
        }`}
      >
        <div className="px-4 md:px-16">
          {" "}
          <>
            <p className="text-gray-700 text-3xl mb-16 font-bold mt-10">
              Applied Jobs
            </p>

            <div className="grid  mb-10 bg-white p-9 rounded-lg overflow-x-auto">
              <table className="table">
                <thead className="text-primary bg-secondary">
                  <tr>
                    <th>Job</th>
                    <th>Date Applied</th>
                    <th>Status</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {listOfApplied.map((value) => {
                    return (
                      <tr className="hover:bg-slate-100">
                        <td>
                          <div
                            onClick={() => navigate(`/job/${value.Job.id}`)}
                            className="text-black text-lg font-semibold mb-1"
                          >
                            {value.Job.Title}
                          </div>
                          <div className="text-slate-600 flex flex-wrap md:flex-col lg:flex-row">
                            <div className="flex items-center font-light  mr-5">
                              <BsBriefcaseFill size={13} className="" />
                              <p className="text-sm">
                                {value.Job.Profession.Name}
                              </p>
                            </div>
                            <div className="flex items-center font-light">
                              <CiLocationOn size={15} className="" />
                              <p className="text-sm">
                                {value.Job.City.Name},{" "}
                                {value.Job.LocalArea.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          {(() => {
                            const date = new Date(value.createdAt);
                            return date.toLocaleDateString();
                          })()}
                        </td>
                        <td
                          className={`text-${
                            value.Status === "Accepted"
                              ? "green-300"
                              : value.status === "Rejected"
                              ? "red-300"
                              : "gray-300"
                          }`}
                        >
                          {value.Status}
                        </td>
                        <td>
                          <button>
                            <XMarkIcon
                              onClick={() => confirmDelete(value.id)}
                              className="h-6 w-6 text-red-500 font-semibold"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </main>
      <ToastContainer />
      <Footer />
    </>
  );
}
