import { useState, useEffect, Fragment } from "react";
import SideBar from "./components/SideBar";
import TopBar from "../TopBar";
import { Transition } from "@headlessui/react";
import Footer from "../../components/Footer";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

export default function Layout({ children }) {
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  function handleResize() {
    if (window.innerWidth <= 640) {
      setShowNav(false);
      setIsMobile(true);
    } else {
      setShowNav(true);
      setIsMobile(false);
    }
  }

  useEffect(() => {
    if (typeof window != undefined) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [successMessage, setSuccessMessage] = useState("");

  const initialValues = {
    username: "",
    UserType: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(3).max(15).required("Username is required"),
    UserType: Yup.string().required("User Type is required"),
    password: Yup.string().min(4).max(20).required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const onSubmit = (data, { setErrors }) => {
    axios
      .post("https://api.askamarlabor.com/serverapi/users", data)
      .then(() => {
        setSuccessMessage("User registration successful!");

        setTimeout(clearSuccessMessage, 5000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // Handle the specific error when username already exists
          setErrors({
            username: "Username already exists try another username",
          });
        } else {
          // Handle other errors
          console.error("Error:", error);
        }
      });
  };

  const clearSuccessMessage = () => {
    setSuccessMessage("");
  };

  return (
    <>
      <TopBar showNav={showNav} setShowNav={setShowNav} />
      <Transition
        as={Fragment}
        show={showNav}
        enter="transform transition duration-[400ms]"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-[400ms] transition ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <SideBar showNav={showNav} />
      </Transition>
      <main
        className={`pt-16 transition-all duration-[400ms] ${
          showNav && !isMobile ? "pl-56" : ""
        }`}
      >
        <div className="px-4 md:px-16">
          {" "}
          <>
            <p className="text-gray-700 text-3xl mb-16 font-bold mt-10">
              Add User
            </p>

            <div className="grid gap-10 mb-10 bg-white p-9 px-16 rounded-lg w-fit">
              {successMessage && (
                <div className="text-green-500 text-sm">{successMessage}</div>
              )}

              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                <Form>
                  <div className="flex flex-col items-center">
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                    <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                      <Field
                        type="text"
                        name="username"
                        placeholder="Username"
                        className="bg-white outline-none text-sm flex-1 ml-2"
                      />
                    </div>
                    <ErrorMessage
                      name="UserType"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                    <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                      <Field
                        as="select"
                        name="UserType"
                        className="bg-white outline-none text-sm flex-1 ml-2"
                      >
                        <option value="" disabled>
                          User Type
                        </option>
                        <option value="Employee">Employee</option>
                        <option value="Employer">Employer</option>
                        <option value="Broker">Broker</option>
                        <option value="Admin">Admin</option>
                      </Field>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                    <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="bg-white outline-none text-sm flex-1 ml-2"
                      />
                    </div>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                    <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className="bg-white outline-none text-sm flex-1 ml-2"
                      />
                    </div>
                    <button
                      type="submit"
                      className="border-2 border-primary text-primary rounded-full px-6 md:px-12 py-2 inline-block font-semibold hover:bg-primary hover:text-white"
                    >
                      Register
                    </button>
                    <p className="mb-0 mt-2 pt-1 text-sm lg:hidden">
                      Already have an account?{" "}
                      <a
                        href="/login"
                        className="text-primary transition duration-150 ease-in-out hover:text-secondary focus:text-danger-600 active:text-danger-700"
                      >
                        Login
                      </a>
                    </p>
                  </div>
                </Form>
              </Formik>
            </div>
          </>
        </div>
      </main>
      <Footer />
    </>
  );
}
