import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";

const ChangePasswordForm = () => {
  const [submitError, setSubmitError] = useState(null);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .min(6, "New Password must be at least 6 characters")
      .required("New Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleChangePassword = (values, { resetForm, setSubmitting }) => {
    setSubmitError(null);

    axios
      .put(
        "https://api.askamarlabor.com/serverapi/users/changepassword",
        values,
        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        if (response.data.error) {
          setSubmitError(response.data.error);
        } else {
          alert("Password changed successfully.");
          resetForm(); // Reset the form after a successful submission
        }
      })
      .catch((error) => {
        console.error("Error changing password:", error);
        setSubmitError("An error occurred while changing the password.");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="grid lg:grid-cols-2 gap-6 mb-10 p-6 rounded-lg bg-white shadow-md">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleChangePassword}
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            <div className="grid grid-cols-1 gap-4">
              <div className="flex flex-col">
                <label className="text-lg text-gray-800" htmlFor="oldPassword">
                  Old Password
                </label>
                <Field
                  type="password"
                  name="oldPassword"
                  id="oldPassword"
                  className="input input-bordered w-full bg-secondary text-black"
                />
                <ErrorMessage
                  name="oldPassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-lg text-gray-800" htmlFor="newPassword">
                  New Password
                </label>
                <Field
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  className="input input-bordered w-full bg-secondary text-black"
                />
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="text-lg text-gray-800"
                  htmlFor="confirmPassword"
                >
                  Confirm Password
                </label>
                <Field
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  className="input input-bordered w-full bg-secondary text-black"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>
            {submitError && (
              <p className="text-red-500 text-sm">{submitError}</p>
            )}
            <div className="flex justify-between mt-6">
              <button
                type="submit"
                className={`btn btn-primary ${
                  isSubmitting ? "cursor-not-allowed" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Save change"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePasswordForm;
