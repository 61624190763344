import { forwardRef } from "react";
import { HomeIcon, CreditCardIcon, UserIcon } from "@heroicons/react/24/solid";
import { DocumentDuplicateIcon,MegaphoneIcon,LockClosedIcon,PowerIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";


import Logo from "../../../assets/Logo.svg"
import { AuthContext } from "../../../helpers/AuthContext";

const SideBar = forwardRef(({ showNav }, ref) => {
  const currentPathname = typeof window !== "undefined" ? window.location.pathname : "";
  const { authState, setAuthState } = useContext(AuthContext);


  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState({ username: "", id: 0, status: false });
  };

  return (
    <div ref={ref} className="fixed w-56 h-full bg-white shadow-sm">
      <div className="flex justify-center mt-6 mb-14">
        <picture>
          <img
            className="w-32 h-auto"
            src={Logo}
            alt="company logo"
          />
        </picture>
      </div>

      <div className="flex flex-col">
        
        <a href="/profile">
          <div
            className={`pl-6 py-3 mx-5 rounded text-center cursor-pointer mb-3 flex items-center transition-colors ${
              currentPathname === "/profile"
                ? "bg-secondary text-primary"
                : "text-gray-400 hover:bg-secondary hover:text-primary"
            }`}
          >
            <div className="mr-2">
              <UserIcon className="h-5 w-5" />
            </div>
            <div>
              <p>Profile</p>
            </div>
          </div>
        </a>
        <a href="/resume">
          <div
            className={`pl-6 py-3 mx-5 rounded text-center cursor-pointer mb-3 flex items-center transition-colors ${
              currentPathname === "/resume"
                ? "bg-secondary text-primary"
                : "text-gray-400 hover:bg-secondary hover:text-primary"
            }`}
          >
            <div className="mr-2">
              <DocumentDuplicateIcon className="h-5 w-5" />
            </div>
            <div>
              <p>Resume</p>
            </div>
          </div>
        </a>
        <a href="/applied">
          <div
            className={`pl-6 py-3 mx-5 rounded text-center cursor-pointer mb-3 flex items-center transition-colors ${
              currentPathname === "/applied"
                ? "bg-secondary text-primary"
                : "text-gray-400 hover:bg-secondary hover:text-primary"
            }`}
          >
            <div className="mr-2">
              <MegaphoneIcon className="h-5 w-5" />
            </div>
            <div>
              <p>Applied</p>
            </div>
          </div>
        </a>
        <a href="/changepassword">
          <div
            className={`pl-6 py-3 mx-5 rounded text-center cursor-pointer mb-3 flex items-center transition-colors ${
              currentPathname === "/changepassword"
                ? "bg-secondary text-primary"
                : "text-gray-400 hover:bg-secondary hover:text-primary"
            }`}
          >
            <div className="mr-2">
              <LockClosedIcon className="h-5 w-5" />
            </div>
            <div>
              <p>Password</p>
            </div>
          </div>
        </a>
        <a href="/">
          <div
            className={`pl-6 py-3 mx-5 rounded text-center cursor-pointer mb-3 flex items-center transition-colors ${
              currentPathname === "/"
                ? "bg-secondary text-primary"
                : "text-gray-400 hover:bg-secondary hover:text-primary"
            }`}
          >
            <div className="mr-2">
              <PowerIcon className="h-5 w-5" />
            </div>
            <div>
              <p onClick={logout}>Log out</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
});

SideBar.displayName = "SideBar";

export default SideBar;
