import React from 'react'
import { CiLocationOn } from "react-icons/ci";
import { BsBriefcaseFill } from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi";

const JobCard = ({details}) => {
    const { id, Title, description, Profession, JobType,City,LocalArea, experience, gender, applicationDeadline,location,minSalary,maxSalary,salaryType } = details;
  return (
    <div className="border-2 border-black rounded-lg px-8 py-5 flex h-40 items-center">
      <div>
        <div className="text-black text-lg font-semibold">{Title}</div>
        <div className="text-slate-600 flex flex-wrap md:flex-col lg:flex-row">
          <div className="flex items-center font-light w-1/2 mt-3">
            <BsBriefcaseFill size={13} className="" />
            <p className="text-sm">{Profession.Name}</p>
          </div>
          <div className="flex items-center font-light w-1/2 mt-3">
            <CiLocationOn size={15} className="" />
            <p className="text-sm">{City.Name}, {LocalArea.name}</p>
          </div>
          <div className="flex items-center font-light w-full mt-3">
            <GiMoneyStack size={17} className="" />
            <p className="text-sm">{minSalary} -  {maxSalary} / {salaryType}</p>
          </div>
        </div>
      </div>
      <div className="text-primary text-xs pt-3 flex ml-auto mb-auto">{JobType.Name}</div>
    </div>
  )
}

export default JobCard