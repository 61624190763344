import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Workers from "./pages/Workers";
import Pagination from "./components/Pagination";
import WorkerProfile from "./pages/WorkerProfile";
import Jobs from "./pages/Jobs";
import JobDetails from "./pages/JobDetails";
import WorkerDashboardProfile from "./dashboard/worker/Profile";
import WorkerDashboardResume from "./dashboard/worker/Resume";
import WorkerDashboardApplied from "./dashboard/worker/Applied";
import WorkerDashboardChangePassword from "./dashboard/worker/ChangePassword";
import EmployerDashboardProfile from "./dashboard/employer/Profile";
import EmployerDashboardPostJob from "./dashboard/employer/PostJob";
import EmployerDashboardMyJobs from "./dashboard/employer/MyJobs";
import EmployerDashboardChangePassword from "./dashboard/employer/ChangePassword";
import EmployerDashboardApplicants from "./dashboard/employer/Applicants";
import BrokerDashboardProfile from "./dashboard/broker/Profile";
import BrokerDashboardAddCandidate from "./dashboard/broker/AddCandidate";
import BrokerDashboardMyCandidate from "./dashboard/broker/MyCandidates";
import BrokerDashboardPostJob from "./dashboard/broker/PostJob";
import BrokerDashboardMyJobs from "./dashboard/broker/MyJobs";
import BrokerDashboardApplicants from "./dashboard/broker/Applicants";
import BrokerDashboardChangePassword from "./dashboard/broker/ChangePassword";
import AdminDashboardPostJob from "./dashboard/admin/PostJob";
import AdminDashboardAddUser from "./dashboard/admin/AddUser";
import AdminDashboardVerifyWorker from "./dashboard/admin/VerifyWorker";
import AdminDashboardMyJobs from "./dashboard/admin/MyJobs";
import AdminDashboardApproveJobs from "./dashboard/admin/ApproveJob";
import AdminDashboardManageReview from "./dashboard/admin/ManageReview";
import AdminDashboardChangePassword from "./dashboard/admin/ChangePassword";
import AdminDashboardApplicants from "./dashboard/admin/Applicants";
import UpdateEmployee from "./pages/UpdateEmployee";
import UpdateJob from "./pages/UpdateJob";

import { AuthContext } from "./helpers/AuthContext";

const App = () => {
  const [authState, setAuthState] = useState({
    username: "",
    usertype: "",
    id: 0,
    status: false,
  });

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/users/auth", {
        headers: { accessToken: localStorage.getItem("accessToken") },
      })
      .then((response) => {
        if (response.data.error) setAuthState({ ...authState, status: false });
        else {
          setAuthState({
            username: response.data.username,
            id: response.data.id,
            usertype: response.data.UserType,
            status: true,
          });
        }
      });
  }, []);

  // Define routes based on user type
  const userRoutes = () => {
    switch (authState.usertype) {
      case "Employee":
        return (
          <>
            <Route path="/profile" element={<WorkerDashboardProfile />} />
            <Route path="/resume" element={<WorkerDashboardResume />} />
            <Route path="/applied" element={<WorkerDashboardApplied />} />
            <Route
              path="/changepassword"
              element={<WorkerDashboardChangePassword />}
            />
          </>
        );
      case "Employer":
        return (
          <>
            <Route path="/profile" element={<EmployerDashboardProfile />} />
            <Route path="/myjobs" element={<EmployerDashboardMyJobs />} />
            <Route path="/postjob" element={<EmployerDashboardPostJob />} />
            <Route
              path="/applicants"
              element={<EmployerDashboardApplicants />}
            />
            <Route
              path="/changepassword"
              element={<EmployerDashboardChangePassword />}
            />
            <Route path="/updatejob/:id" element={<UpdateJob />} />
          </>
        );
      case "Broker":
        return (
          <>
            <Route path="/profile" element={<BrokerDashboardProfile />} />
            <Route
              path="/addcandidate"
              element={<BrokerDashboardAddCandidate />}
            />
            <Route
              path="/mycandidate"
              element={<BrokerDashboardMyCandidate />}
            />
            <Route path="/applicants" element={<BrokerDashboardApplicants />} />
            <Route path="/myjobs" element={<BrokerDashboardMyJobs />} />
            <Route path="/postjob" element={<BrokerDashboardPostJob />} />
            <Route
              path="/changepassword"
              element={<BrokerDashboardChangePassword />}
            />
            <Route path="/update/:id" element={<UpdateEmployee />} />
            <Route path="/updatejob/:id" element={<UpdateJob />} />
          </>
        );
      case "Admin":
        return (
          <>
            <Route path="/postjob" element={<AdminDashboardPostJob />} />
            <Route path="/adduser" element={<AdminDashboardAddUser />} />
            <Route
              path="/verifyworkers"
              element={<AdminDashboardVerifyWorker />}
            />
            <Route path="/myjobs" element={<AdminDashboardMyJobs />} />
            <Route
              path="/approvejobs"
              element={<AdminDashboardApproveJobs />}
            />
            <Route path="/applicants" element={<AdminDashboardApplicants />} />
            <Route
              path="/managereview"
              element={<AdminDashboardManageReview />}
            />
            <Route
              path="/changepassword"
              element={<AdminDashboardChangePassword />}
            />
            <Route path="/updatejob/:id" element={<UpdateJob />} />
          </>
        );
      default:
        return null; // Handle other user types or unauthenticated users
    }
  };

  return (
    <div>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/workers" element={<Workers />} />
            <Route path="/pagination" element={<Pagination />} />
            <Route path="/worker/:id" element={<WorkerProfile />} />
            <Route path="/job/:id" element={<JobDetails />} />
            <Route path="/jobs" element={<Jobs />} />
            {authState.status && userRoutes()}
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
