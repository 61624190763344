import React, { useState, useEffect, useContext } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import hero from "../assets/hero.svg";
import { AiOutlineSearch } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import TopBar from "../components/TopBar";
import { AuthContext } from "../helpers/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { authState } = useContext(AuthContext);
  const [cityOptions, setCityOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/cities")
      .then((response) => {
        setCityOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching city data:", error);
      });
  }, []);

  const [selectedCity, setSelectedCity] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleSearch = () => {
    // Use the navigate function to navigate to the Workers page with query parameters
    navigate(
      `/workers?searchTerm=${searchTerm}&selectedLocation=${selectedCity}`
    );
  };

  return (
    <div>
      {authState.status ? <TopBar /> : <Navbar />}

      <section className="py-5 text-black">
        <div className="mb-8 flex flex-col justify-center gap-6 sm:gap-10 md:mb-16 md:gap-16 lg:flex-row">
          <div className="flex flex-col justify-center sm:text-center lg:py-12 lg:text-left xl:w-5/12">
            <h1 className="mb-8 text-4xl font-bold text-slate-600 sm:text-5xl text-center">
              Find <span className="text-primary">Your</span> Perfect{" "}
              <span className="text-primary">Employee</span>
            </h1>
            <p className="text-slate-600 mb-7 max-w-xl font-medium font-sans text-center lg:max-w-md text-l lg:text-xl mx-auto">
              Unlock a World of Talent and Connect with the Perfect Candidates
              to Shape Your Future Success.
            </p>

            <div className="flex flex-col gap-2.5 sm:flex-row sm:justify-center lg:justify-start mx-auto">
              <a
                href="/signup"
                className="bg-primary text-white py-3 px-6 rounded-md hover:bg-slate-100 hover:text-[16px] hover:text-primary duration-500"
              >
                Get started
              </a>
            </div>
          </div>

          <img src={hero} alt="pic" />
        </div>

        <div className="px-8 md:px-0 w-full max-w-lg lg:max-w-xl flex flex-col md:flex-row gap-2 mx-auto">
          <div className="relative mb-4 md:mr-2 flex-1">
            <input
              type="text"
              className="pl-10 pr-4 py-2 w-full rounded-lg bg-white focus:outline-none focus:shadow-outline"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className=" absolute top-0 left-0 px-3 py-3">
              <AiOutlineSearch size={17} />
            </div>
          </div>
          <div className="relative mb-4 md:mr-2 flex-1">
            <select
              onChange={handleCityChange}
              className="pl-10 pr-4 py-2 w-full rounded-lg bg-white focus:outline-none focus:shadow-outline"
            >
              <option value="">All Cities</option>
              {cityOptions.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.Name}
                </option>
              ))}
            </select>
            <div className=" absolute top-0 left-0 px-3 py-3">
              <CiLocationOn size={17} />
            </div>
          </div>
          <button
            onClick={handleSearch}
            className="h-10 px-6 bg-primary text-white rounded-lg w-full md:w-auto"
          >
            Search
          </button>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
