import React, { useState, useContext, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { CiLocationOn, CiViewTimeline } from "react-icons/ci";
import { BsFillCalendarMinusFill, BsFillPersonFill } from "react-icons/bs";
import { BiSolidDownload, BiPhone, BiBriefcase } from "react-icons/bi";
import { RiLoopLeftLine } from "react-icons/ri";
import { GiMoneyStack } from "react-icons/gi";
import { AiOutlineClose, AiOutlineClockCircle } from "react-icons/ai";
import { CgSandClock } from "react-icons/cg";
import JobsDummyData from "../components/JobsDummyData";
import TopBar from "../components/TopBar";
import { AuthContext } from "../helpers/AuthContext";
import { confirmAlert } from "react-confirm-alert"; // Import react-confirm-alert
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JobDetails = () => {
  const { id } = useParams();
  const jobId = parseInt(id);

  const { authState } = useContext(AuthContext);
  const [job, setJob] = useState({});
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    axios
      .get(`https://api.askamarlabor.com/serverapi/jobs/byid/${id}`, {
        headers: { accessToken: localStorage.getItem("accessToken") },
      })
      .then((response) => {
        setJob(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setNotFound(true); // Set the notFound state to true if 404 response
        }
      });
  }, [id]);

  const confirmApply = () => {
    confirmAlert({
      title: "Confirm Apply",
      message: "Are you sure you want to apply for this job?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleApply(),
        },
        {
          label: "No",
          onClick: () => {}, // Do nothing on "No" button click
        },
      ],
    });
  };

  const handleApply = async () => {
    try {
      // Prepare the request data
      const requestData = { JobId: jobId };

      // Send a POST request to apply for the job
      const response = await axios.post(
        `https://api.askamarlabor.com/serverapi/appliedjobs/apply`,
        requestData,
        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      );

      // Application was successful, show a success message
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      if (error.response) {
        // Server responded with an error message
        toast.error(error.response.data.error, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (error.request) {
        // The request was made but no response was received (network error)
        toast.error("Network error. Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        // Something else went wrong
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  if (notFound) {
    return (
      <div className="flex items-center justify-center h-screen bg-secondary">
        <div className="max-w-md w-full p-6 bg-white shadow-md rounded-lg text-center">
          <h2 className="text-2xl font-semibold mb-4">Job Not Found</h2>
          <p className="text-gray-600 mb-4">
            The job you are looking for does not exist.
          </p>
          <Link to="/jobs" className="text-primary hover:underline">
            Go back to jobs list
          </Link>
        </div>
      </div>
    );
  }
  const {
    title,
    description,
    city,
    datePosted,
    deadline,
    localArea,
    profession,
    jobType,
    experience,
    gender,
    applicationDeadline,
    location,
    minSalary,
    maxSalary,
    salaryType,
  } = job;

  return (
    <div>
      {authState.status ? <TopBar /> : <Navbar />}
      <div className=" mb-10">
        <div className="bg-white text-black px-5 lg:px-20 py-3 flex flex-col lg:flex-row items-center gap-2 lg:gap-8 mb-5 justify-center">
          <div className="flex flex-col gap-2 pt-2">
            <h2 className="text-3xl text-center lg:text-left">{title}</h2>
            <div className="flex items-center gap-8">
              <div className="flex items-center font-light">
                <BiBriefcase size={16} className=" pb-[2px]" />
                <p className="text-sm">{profession}</p>
              </div>
              <div className="flex items-center font-light">
                <CiLocationOn size={16} className=" pb-[2px]" />
                <p className="text-sm">
                  {city}, {localArea}
                </p>
              </div>
              <div className="flex items-center font-light">
                <GiMoneyStack size={16} className=" text-slate-800 " />
                <p className="text-sm">
                  {minSalary}-{maxSalary} / {salaryType}
                </p>{" "}
              </div>
            </div>
            <div className="rating mb-4 mx-auto lg:mx-0">
              <p href="#" className="text-primary text-sm">
                {jobType}
              </p>
            </div>
          </div>
          <div className="ml-auto flex gap-4 mx-auto lg:mr-0">
            {authState.usertype === "Employee" && (
              <button
                onClick={() => {
                  confirmApply();
                }}
                className="bg-primary text-white py-3 px-6 rounded-md hover:bg-secondary hover:text-[16px] hover:text-primary duration-500 flex items-center gap-2"
              >
                {" "}
                Apply
              </button>
            )}
          </div>
        </div>
        <div className="px-5 md:px-10 lg:px-20 flex justify-center gap-8 text-black flex-col lg:flex-row">
          <div className=" lg:w-3/5 w-full order-2 lg:order-none">
            <h2 className="text-lg font-semibold text-center md:text-left">
              Description
            </h2>
            <p className=" font-light py-3 text-base">{description}</p>
          </div>
          <div className="bg-white lg:w-2/5 w-full rounded-2xl p-9 flex flex-col gap-10">
            <div className="flex items-center gap-5">
              <BsFillCalendarMinusFill size={24} color="#1967D2" />
              <div className="text-sm">
                <p className=" font-semibold">Date posted</p>
                <p className=" text-xs">{datePosted}</p>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <CiLocationOn size={24} color="#1967D2" />
              <div className="text-sm">
                <p className=" font-semibold">Location</p>
                <p className=" text-xs">
                  {city}, {localArea}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <GiMoneyStack size={20} color="#1967D2" />
              <div className="text-sm">
                <p className=" font-semibold">Offerd Salary</p>
                <p className=" text-xs">
                  {minSalary}-{maxSalary}/{salaryType}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <AiOutlineClockCircle size={24} color="#1967D2" />
              <div className="text-sm">
                <p className=" font-semibold">Deadline Date</p>
                <p className=" text-xs">{deadline}</p>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <CgSandClock size={24} color="#1967D2" />
              <div className="text-sm">
                <p className=" font-semibold">Experience</p>
                <p className=" text-xs">{experience} year</p>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <BsFillPersonFill size={24} color="#1967D2" />
              <div className="text-sm">
                <p className=" font-semibold">Gender</p>
                <p className=" text-xs">{gender}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Footer />
    </div>
  );
};

export default JobDetails;
