import { useState, useEffect, Fragment } from "react";
import SideBar from "./components/SideBar";
import TopBar from "../TopBar";
import { Transition } from "@headlessui/react";
import Footer from "../../components/Footer";
import { CiLocationOn } from "react-icons/ci";
import { BsBriefcaseFill } from "react-icons/bs";
import { XMarkIcon, PencilSquareIcon } from "@heroicons/react/24/solid";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert"; // Import react-confirm-alert
import "react-confirm-alert/src/react-confirm-alert.css";

export default function Layout({ children }) {
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [listOfJobs, setListOfJobs] = useState([]);
  const navigate = useNavigate();

  function handleResize() {
    if (window.innerWidth <= 640) {
      setShowNav(false);
      setIsMobile(true);
    } else {
      setShowNav(true);
      setIsMobile(false);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this candidate's profile?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => {}, // Do nothing on "No" button click
        },
      ],
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.askamarlabor.com/serverapi/jobs/bybroker/${id}`,
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        toast.success("job deleted successfully!", {
          position: toast.POSITION.TOP_CENTER,
        });

        setTimeout(() => {
          setListOfJobs((candidates) =>
            candidates.filter((candidate) => candidate.id !== id)
          );
        }, 2000);
      } else {
        console.error(
          "Error deleting job. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error deleting job:", error);
      toast.error("Error deleting job. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/jobs/bybrokerid", {
        headers: { accessToken: localStorage.getItem("accessToken") },
      })
      .then((response) => {
        setListOfJobs(response.data);
      });
  }, []);

  return (
    <>
      <TopBar showNav={showNav} setShowNav={setShowNav} />
      <Transition
        as={Fragment}
        show={showNav}
        enter="transform transition duration-[400ms]"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-[400ms] transition ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <SideBar showNav={showNav} />
      </Transition>
      <main
        className={`pt-16 transition-all duration-[400ms] ${
          showNav && !isMobile ? "pl-56" : ""
        }`}
      >
        <div className="px-4 md:px-16">
          {" "}
          <>
            <p className="text-gray-700 text-3xl mb-16 font-bold mt-10">
              My Jobs
            </p>

            <div className="grid  mb-10 bg-white p-9 rounded-lg overflow-x-auto">
              <table className="table">
                <thead className="text-primary bg-secondary">
                  <tr>
                    <th>Title</th>
                    <th>Created & Expired</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {listOfJobs.map((value) => {
                    return (
                      <tr className="hover:bg-slate-100">
                        <td>
                          <div className="text-black text-lg font-semibold mb-1">
                            {value.Title}
                          </div>
                          <div className="text-slate-600 flex flex-wrap md:flex-col lg:flex-row">
                            <div className="flex items-center font-light">
                              <CiLocationOn size={15} className="" />
                              <p className="text-sm">
                                {value.City.Name}, {value.LocalArea.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="grid">
                            <p>
                              <span className="font-semibold text-black">
                                Posted on:{" "}
                              </span>
                              {(() => {
                                const date = new Date(value.createdAt);
                                return date.toLocaleDateString();
                              })()}
                            </p>
                            <p>
                              <span className="font-semibold text-black">
                                Expires on:{" "}
                              </span>
                              {value.expires
                                ? (() => {
                                    const date = new Date(value.expires);
                                    return date.toLocaleDateString();
                                  })()
                                : null}
                            </p>
                          </div>
                        </td>
                        <td
                          className={`text-${
                            value.status === "Approved"
                              ? "green-300"
                              : value.status === "Rejected"
                              ? "red-300"
                              : "gray-300"
                          }`}
                        >
                          {value.status}
                        </td>
                        <td>
                          <Link to={`/updatejob/${value.id}`}>
                            <button>
                              <PencilSquareIcon class="h-6 w-6 text-primary font-semibold mr-3" />
                            </button>
                          </Link>
                          <button onClick={() => confirmDelete(value.id)}>
                            <XMarkIcon class="h-6 w-6 text-red-500 font-semibold" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </main>
      <Footer />
    </>
  );
}
