import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const Login = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const login = () => {
    const data = { username: username, password: password };
    axios
      .post("https://api.askamarlabor.com/serverapi/users/login", data)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
        } else {
          setAuthState({
            username: response.data.username,
            id: response.data.id,
            usertype: response.data.UserType,
            status: true,
          });

          localStorage.setItem("accessToken", response.data.token);
          navigate("/");
        }
      });
  };

  return (
    <main className="flex flex-col items-center justify-center w-full min-h-screen px-4 md:px-20 flex-1 font-poppins">
      <div className="bg-white rounded-2xl shadow-2xl flex flex-col md:flex-row w-full md:w-2/3 max-w-4xl text-center">
        <div className="w-full lg:w-3/5 p-5 text-black">
          <div className="text-left font-bold">
            <span className="text-primary">ASKAMAR</span>Labor
          </div>
          <div className="py-10">
            <h2 className="text-3xl font-bold text-primary mb-4">Sign in</h2>
            <div className="border-2 w-10 border-primary inline-block mb-8"></div>
            <div className="flex flex-col items-center">
              <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  className="bg-white outline-none text-sm flex-1 ml-2"
                  onChange={(event) => setUsername(event.target.value)}
                />
              </div>
              <div className="w-full md:w-64 border-2 rounded-md border-secondary hover:border-primary p-2 flex items-center mb-6">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  className="bg-white outline-none text-sm flex-1 ml-2"
                  onChange={(event) => setPassword(event.target.value)}
                />
                <button
                  className="text-secondary"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <MdOutlineVisibilityOff />
                  ) : (
                    <MdOutlineVisibility />
                  )}
                </button>
              </div>
              <button
                onClick={login}
                className="border-2 border-primary text-primary rounded-full px-6 md:px-12 py-2 inline-block font-semibold hover:bg-primary hover:text-white"
              >
                Sign in
              </button>
              <p className="mb-0 mt-2 pt-1 text-sm lg:hidden">
                Don't have an account?{" "}
                <a
                  href="/signup"
                  className="text-primary transition duration-150 ease-in-out hover:text-secondary focus:text-danger-600 active:text-danger-700"
                >
                  Register
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="w-2/5 hidden lg:block bg-primary text-white rounded-tr-2xl rounded-br-2xl px-12 py-[86px]">
          <h1 className="text-3xl font-poppins font-bold mb-2">
            Don't have an account?
          </h1>
          <div className="border-2 w-10 border-white inline-block mb-2"></div>
          <p className="mb-10">
            Register and create account to get started with us.
          </p>
          <a
            href="/signup"
            className="border-2 border-white rounded-full px-12 py-2 inline-block font-semibold hover:bg-white hover:text-primary"
          >
            {" "}
            Sign Up
          </a>
        </div>
      </div>
    </main>
  );
};

export default Login;
