import React from "react";
import TopBar from "../components/TopBar";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment, useContext } from "react";
import { AuthContext } from "../helpers/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateEmployee() {
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  let { id } = useParams();
  const [formData, setFormData] = useState({
    fullName: "",
    gender: "",
    age: 0,
    phoneNumber: "",
    experience: 0,
    description: "",
    salary: 0,
    salaryType: "",
    isVerified: false,
    profileImage: null,
    city: "", // Initialize city with an empty string
    localArea: "", // Initialize localArea with an empty string
    profession: "", // Initialize profession with an empty string
  });
  const [userId, setUserId] = useState("");

  const [profileImageName, setProfileImageName] = useState("");

  const [cityOptions, setCityOptions] = useState([]);
  const [localAreaOptions, setLocalAreaOptions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/cities")
      .then((response) => {
        setCityOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching city data:", error);
      });

    axios
      .get("https://api.askamarlabor.com/serverapi/professions")
      .then((response) => {
        setProfessionOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profession data:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch existing profile data and update the form
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(
          `https://api.askamarlabor.com/serverapi/profile/byemployeeid/${id}`,
          {
            headers: { accessToken: localStorage.getItem("accessToken") },
          }
        );
        const profileData = response.data; // Replace with your API response structure

        setFormData({
          fullName: profileData.fullName,
          gender: profileData.gender,
          age: profileData.age,
          phoneNumber: profileData.phoneNumber,
          experience: profileData.experience,
          description: profileData.description,
          salary: profileData.salary,
          salaryType: profileData.salaryType,
          isVerified: profileData.isVerified,
          city: profileData.CityId,
          localArea: profileData.localareaid,
          profession: profileData.ProfessionId,
          profileImage: null, // Set this based on your data or leave it as null
        });

        setUserId(profileData.userId);

        // Set the profile image name for display
        setProfileImageName(profileData.profileImageName); // Replace with your actual profile image property

        if (profileData.CityId) {
          axios
            .get(
              `https://api.askamarlabor.com/serverapi/localareas/bycity?city=${profileData.CityId}`
            )
            .then((response) => {
              setLocalAreaOptions(response.data);
            })
            .catch((error) => {
              console.error("Error fetching local area data by city:", error);
            });
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, []);

  const [selectedCity, setSelectedCity] = useState(formData.city);
  const [selectedLocalArea, setSelectedLocalArea] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");

  useEffect(() => {
    if (selectedCity) {
      axios
        .get(
          `https://api.askamarlabor.com/serverapi/localareas/bycity?city=${selectedCity}`
        )
        .then((response) => {
          setLocalAreaOptions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching local area data by city:", error);
        });
    }
  }, [selectedCity]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === "profileImage" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataForUpdate = new FormData();
    formDataForUpdate.append("fullName", formData.fullName);
    formDataForUpdate.append("gender", formData.gender);
    formDataForUpdate.append("age", formData.age);
    formDataForUpdate.append("phoneNumber", formData.phoneNumber);
    formDataForUpdate.append("experience", formData.experience);
    formDataForUpdate.append("description", formData.description);
    formDataForUpdate.append("salary", formData.salary);
    formDataForUpdate.append("salaryType", formData.salaryType);
    formDataForUpdate.append("CityId", formData.city);
    formDataForUpdate.append("localareaid", formData.localArea);
    formDataForUpdate.append("ProfessionId", formData.profession);

    formDataForUpdate.append("profileImage", formData.profileImage);

    try {
      const response = await axios.put(
        `https://api.askamarlabor.com/serverapi/profile/bybroker/${id}`,
        formDataForUpdate,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        // Handle success, e.g., display a success message to the user
        toast.success("Profile updated successfully!", {
          position: toast.POSITION.TOP_CENTER,
        });

        // Delay for 2 seconds before redirecting to the updated profile page
        setTimeout(() => {
          navigate(`/mycandidate`);
        }, 2000); // 2000 milliseconds (2 seconds)
      } else {
        console.error(
          "Error updating profile. Server responded with status:",
          response.status
        );
        // Handle the specific server response status code as needed
      }
    } catch (error) {
      console.error("Error updating profile:", error);

      if (error.response) {
        // The request was made, but the server responded with a non-2xx status code
        console.error("Server responded with status:", error.response.status);
        console.error("Server response data:", error.response.data);

        // Handle specific error cases based on the status code
        if (error.response.status === 404) {
          // Handle 404 Not Found error
        } else if (error.response.status === 500) {
          // Handle 500 Internal Server Error
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error(
          "No response received from the server. Check server availability."
        );
      } else {
        // Something else happened while setting up the request
        console.error("Error setting up the request:", error.message);
      }
    }
  };
  if (userId === authState.id) {
    return (
      <div>
        <TopBar />

        <div className="px-4 md:px-16">
          {" "}
          <>
            <p className="text-gray-700 text-3xl mb-16 font-bold mt-10">
              Update Profile
            </p>
            <form onSubmit={handleSubmit}>
              <div className="grid lg:grid-cols-2 gap-10 mb-10 bg-white p-9 rounded-lg">
                <div className="grid col-span-full">
                  <p className="text-black font-semibold mb-4">
                    Profile picture
                  </p>

                  <input
                    type="file"
                    className="file-input file-input-bordered file-input-info w-full max-w-xs bg-white block text-sm text-gray-500"
                    name="profileImage"
                    onChange={handleChange}
                    accept=".jpeg, .jpg, .png, .gif, .bmp, .tiff, .webp, .svg, .jfif, .ico"
                  />
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Full Name</p>

                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Gender</p>

                  <select
                    name="gender"
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Age</p>

                  <input
                    type="number"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Phone number</p>

                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">City</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.city}
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                      handleChange(e); // Call the handleChange function
                    }}
                    name="city"
                    required
                  >
                    <option value="">Select a city</option>
                    {cityOptions.map((city) => (
                      <option key={city.id} value={city.id}>
                        {" "}
                        {/* Updated this line */}
                        {city.Name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="grid">
                  <p className="text-black font-semibold mb-4">Local Area</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    name="localArea"
                    value={formData.localArea}
                    onChange={(e) => {
                      setSelectedLocalArea(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value="">Select a local area</option>
                    {localAreaOptions.map((localArea) => (
                      <option key={localArea.id} value={localArea.id}>
                        {localArea.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Profession</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.profession}
                    name="profession"
                    onChange={(e) => {
                      setSelectedProfession(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value="">Select a profession</option>
                    {professionOptions.map((profession) => (
                      <option key={profession.id} value={profession.id}>
                        {profession.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">
                    Experience (year)
                  </p>

                  <input
                    type="number"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    placeholder=""
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>

                <div className="grid col-span-full">
                  <p className="text-black font-semibold mb-4">Description</p>

                  <textarea
                    className="textarea textarea-info h-60 bg-secondary text-black"
                    placeholder="Description (about me)"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Salary (birr)</p>

                  <input
                    type="number"
                    name="salary"
                    value={formData.salary}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Salary type</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.salaryType}
                    onChange={handleChange}
                    name="salaryType"
                    required
                  >
                    <option disabled>Select salary type</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Hourly">Hourly</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Daily">Daily</option>
                  </select>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-outline border-primary hover:text-primary hover:bg-white bg-primary text-white mb-10"
              >
                Update profile
              </button>
            </form>
          </>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default UpdateEmployee;
