import React, { useState } from "react";
import Logo from "../assets/Logo.svg";
import { TfiMenu, TfiClose } from "react-icons/tfi";

const Navbar = () => {
  const [nav, setNav] = useState(true);

  const handlenav = () => {
    setNav(!nav);
  };

  return (
    <div className=" text-black flex justify-between items-center h-24 mx-auto bg-slate-400 p-12 font-poppins relative z-50">
      <a href="/">
        <img className="w-40 h-16" src={Logo} alt="" />
      </a>
      <ul className="lmd:flex hidden text-sm">
        <li className="py-4 px-8">
          <a href="/" className=" hover:text-primary duration-500">
            Home
          </a>
        </li>
        <li className="py-4 px-8">
          <a href="/jobs" className=" hover:text-primary duration-500">
            Jobs
          </a>
        </li>
        <li className="py-4 px-8">
          <a href="/workers" className=" hover:text-primary duration-500">
            Workers
          </a>
        </li>
      </ul>
      <ul className="lmd:flex gap-3 justify-between hidden text-sm">
        <li className="p-4 ">
          <a
            href="/login"
            className="hover:text-[16px] hover:text-primary duration-500"
          >
            Login
          </a>
        </li>
        <li className="p-4 ">
          <a
            href="/signup"
            className="bg-primary text-white py-3 px-6 rounded-md hover:bg-slate-500 hover:text-[16px] hover:text-primary duration-500"
          >
            Sign up
          </a>
        </li>
      </ul>
      <div onClick={handlenav} className="block lmd:hidden">
        {!nav ? <TfiClose size={20} /> : <TfiMenu size={20} />}
      </div>
      <div
        className={
          !nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-300 bg-gray-600 ease-in-out duration-500 lmd:hidden"
            : "fixed left-[-100%] "
        }
      >
        <img className="w-40 h-16 m-4" src={Logo} alt="" />
        <ul className="p-4 uppercase ">
          <li className="p-4 border-b border-gray-500">
            <a href="/">Home</a>
          </li>
          <li className="p-4 border-b border-gray-500">
            <a href="/jobs">Jobs</a>
          </li>
          <li className="p-4 border-b border-gray-500">
            <a href="/workers">Workers</a>
          </li>
          <li className="p-4 border-b border-gray-500">Contact</li>
          <li className="p-4 border-b border-gray-500 ">
            <a href="/login">Login</a>
          </li>
          <li className="p-4 ">
            <a
              href="/signup"
              className="bg-primary text-white py-3 px-6 rounded-md"
            >
              Sign up
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
