import React from 'react'
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = () => {
  

  return (
    <footer className="bg-slate-500 text-center text-neutral-600  dark:text-neutral-200 lg:text-left">
      <div className="flex items-center justify-center border-b-2 border-secondary p-6  lg:justify-between">
        <div className="mr-12 hidden lg:block">
          <span>Connect with us on our socials</span>
        </div>

        <div className="flex justify-center">
          <a
            href="#!"
            className="mr-6  text-slate-200 hover:text-primary duration-500"
          >
            <FaFacebook />
          </a>
          <a
            href="#!"
            className="mr-6  text-slate-200 hover:text-primary duration-500"
          >
            <FaTwitter />
          </a>

          <a
            href="#!"
            className="mr-6  text-slate-200 hover:text-[#f00] duration-500"
          >
            <FaInstagram />
          </a>
          <a
            href="#!"
            className="mr-6  text-slate-200 hover:text-primary duration-500"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>

      <div className="mx-6 py-10 text-center md:text-left">
        <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="">
            <h6 className="mb-4 flex justify-center uppercase font-bold md:justify-start">
              ASKAMAR Labor
            </h6>
            <p className="mb-4">
              <a
                href="#!"
                className="text-neutral-600 dark:text-neutral-200 hover:tracking-widest duration-200"
              >
                About Us
              </a>
            </p>
            <p className="mb-4">
              <a
                href="#!"
                className="text-neutral-600 dark:text-neutral-200 hover:tracking-widest duration-200"
              >
                Contact Us
              </a>
            </p>
            <p className="mb-4">
              <a
                href="#!"
                className="text-neutral-600 dark:text-neutral-200 hover:tracking-widest duration-200"
              >
                Terms and Condition
              </a>
            </p>
          </div>

          <div className="">
            <h6 className="mb-4 flex justify-center uppercase font-bold md:justify-start">
              Pages
            </h6>
            <p className="mb-4">
              <a
                href="/workers"
                className="text-neutral-600 dark:text-neutral-200 hover:tracking-widest duration-200"
              >
                Workers
              </a>
            </p>
            <p className="mb-4">
              <a
                href="/jobs"
                className="text-neutral-600 dark:text-neutral-200 hover:tracking-widest duration-200"
              >
                Jobs
              </a>
            </p>
          </div>

          <div>
            <h6 className="mb-4 flex justify-center uppercase font-bold md:justify-start">
              Contact
            </h6>

            <p className="mb-4 flex items-center justify-center md:justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-5 w-5"
              >
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>
              ASKAMARLabor@gmail.com
            </p>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>
              + 251-1123-4544
            </p>
          </div>
        </div>
      </div>

      <div className="bg-slate-600 p-6 text-center">
        <span>© 2023 Copyright:{"    "}</span>
        <a className="font-semibold text-neutral-600 dark:text-neutral-400" href="">
          Askamar labor
        </a>
      </div>
    </footer>
  );
};

export default Footer;
