import React from "react";
import ReactPaginate from "react-paginate";
import { BsChevronBarRight, BsChevronBarLeft } from "react-icons/bs";
import { motion } from "framer-motion";
const pagination = () => {

    const paginationVariants = {

        hidden:{
            opacity: 0,
            y:200,
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                type:"spring",
                stiffness:260,
                damping:20,
                duration:1
            }
        }


        
    }

  return (

    

    <motion.div variants={paginationVariants} initial="hidden" animate="visible"  className="text-black">
      <ReactPaginate
        breakLabel={<span className="mr-4">...</span>}
        nextLabel={
          <span className="w-10 h-10 flex items-center justify-center bg-primary text-white rounded-md">
            <BsChevronBarRight />
          </span>
        }
        // onPageChange={handleCageClick}
        pageRangeDisplayed={3}
        pageCount={20}
        previousLabel={
          <span className="w-10 h-10 flex items-center justify-center bg-primary text-white rounded-md mr-4">
            <BsChevronBarLeft />
          </span>
        }
        containerClassName="flex items-center justify-center mt-8 mb-4"
        pageClassName="block border border-solid border-slate-400 hover:bg-white hover:text-primary w-10 h-10 flex items-center justify-center rounded-md mr-4"
        activeClassName="bg-primary text-white"
      />
    </motion.div>
  );
};

export default pagination;
