import { useState, useEffect, Fragment } from "react";
import SideBar from "./components/SideBar";
import TopBar from "../TopBar";
import { Transition } from "@headlessui/react";
import Footer from "../../components/Footer";
import {
  XMarkIcon,
  PencilSquareIcon,
  CheckIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/solid";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function Layout({ children }) {
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const [listOfApplicants, setListOfApplicants] = useState([]);

  function handleResize() {
    if (window.innerWidth <= 640) {
      setShowNav(false);
      setIsMobile(true);
    } else {
      setShowNav(true);
      setIsMobile(false);
    }
  }

  useEffect(() => {
    if (typeof window != undefined) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://api.askamarlabor.com/serverapi/appliedjobs/appliedWorkersForAJob",
        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        setListOfApplicants(response.data);
      });
  }, []);

  const handleDownload = async (profileId) => {
    try {
      const response = await axios.get(
        `https://api.askamarlabor.com/serverapi/resume/download/${profileId}`,
        {
          responseType: "blob", // Set the response type to blob
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      );

      // Create a blob URL from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `documents_${profileId}.zip`);
      document.body.appendChild(link);
      link.click();

      // Clean up the temporary link
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading resumes:", error);
      // Handle the error as needed
    }
  };

  const handleApprove = async (applicaationId) => {
    try {
      const response = await axios.put(
        `https://api.askamarlabor.com/serverapi/appliedjobs/updateApproveApplicant/${applicaationId}`,
        null, // Use null for the request body if you don't need to send data
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        axios
          .get(
            "https://api.askamarlabor.com/serverapi/appliedjobs/appliedWorkersForAJob",
            {
              headers: { accessToken: localStorage.getItem("accessToken") },
            }
          )
          .then((response) => {
            setListOfApplicants(response.data);
          });
      } else {
        // Handle errors if needed.
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error:", error);
    }
  };

  const handleReject = async (applicaationId) => {
    try {
      const response = await axios.put(
        `https://api.askamarlabor.com/serverapi/appliedjobs/updateRejectApplicant/${applicaationId}`,
        null, // Use null for the request body if you don't need to send data
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        axios
          .get(
            "https://api.askamarlabor.com/serverapi/appliedjobs/appliedWorkersForAJob",
            {
              headers: { accessToken: localStorage.getItem("accessToken") },
            }
          )
          .then((response) => {
            setListOfApplicants(response.data);
          });
      } else {
        // Handle errors if needed.
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error:", error);
    }
  };

  return (
    <>
      <TopBar showNav={showNav} setShowNav={setShowNav} />
      <Transition
        as={Fragment}
        show={showNav}
        enter="transform transition duration-[400ms]"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-[400ms] transition ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <SideBar showNav={showNav} />
      </Transition>
      <main
        className={`pt-16 transition-all duration-[400ms] ${
          showNav && !isMobile ? "pl-56" : ""
        }`}
      >
        <div className="px-4 md:px-16">
          {" "}
          <>
            <p className="text-gray-700 text-3xl mb-16 font-bold mt-10">
              Job Applicants
            </p>

            <div className="grid  bg-white gap-5 mb-10  p-9 rounded-lg">
              {listOfApplicants.map((value) => {
                return (
                  <div className="flex justify-between border p-6 rounded-md">
                    <div className="grid">
                      <p
                        onClick={() =>
                          navigate(`/worker/${value.EmployeeProfile.id}`)
                        }
                        className="text-black text-lg font-semibold"
                      >
                        {value.EmployeeProfile.fullName}
                      </p>
                      <p className="flex">
                        aplpied for:{" "}
                        <span
                          onClick={() => navigate(`/job/${value.Job.id}`)}
                          className="text-primary"
                        >
                          {value.Job.Title}
                        </span>
                      </p>

                      <p>
                        Applied on:{" "}
                        {(() => {
                          const date = new Date(value.createdAt);
                          return date.toLocaleDateString();
                        })()}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <button>
                        <DocumentArrowDownIcon
                          onClick={() =>
                            handleDownload(value.EmployeeProfile.id)
                          }
                          class="h-6 w-6 text-blue-500 font-semibold mr-3"
                        />
                      </button>
                      <button>
                        <CheckIcon
                          onClick={() => handleApprove(value.id)}
                          class="h-6 w-6 text-blue-500 font-semibold mr-3"
                        />
                      </button>
                      <button>
                        <XMarkIcon
                          onClick={() => handleReject(value.id)}
                          class="h-6 w-6 text-red-500 font-semibold"
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      </main>
      <Footer />
    </>
  );
}
