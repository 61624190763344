import React from "react";
import TopBar from "../components/TopBar";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment, useContext } from "react";
import { AuthContext } from "../helpers/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateJob = () => {
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  let { id } = useParams();
  const [formData, setFormData] = useState({
    Title: "",
    Description: "",
    experience: null,
    gender: "",
    deadline: "",
    minSalary: null,
    maxSalary: null,
    status: "Pending",
    salaryType: "",

    city: "",
    localArea: "",
    profession: "",
    jobtypeid: "",
  });
  const [userId, setUserId] = useState("");

  const [cityOptions, setCityOptions] = useState([]);
  const [localAreaOptions, setLocalAreaOptions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [jobtypeOptions, setJobTypeOptions] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/cities")
      .then((response) => {
        setCityOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching city data:", error);
      });

    axios
      .get("https://api.askamarlabor.com/serverapi/professions")
      .then((response) => {
        setProfessionOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profession data:", error);
      });
    axios
      .get("https://api.askamarlabor.com/serverapi/localareas")
      .then((response) => {
        setLocalAreaOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching local area data:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch existing profile data and update the form
    const fetchJobData = async () => {
      try {
        const response = await axios.get(
          `https://api.askamarlabor.com/serverapi/jobs/byjobid/${id}`,
          {
            headers: { accessToken: localStorage.getItem("accessToken") },
          }
        );
        const jobData = response.data; // Replace with your API response structure
        const formattedDeadline = jobData.deadline.split("T")[0];
        setFormData({
          Title: jobData.Title,
          Description: jobData.Description,
          experience: jobData.experience,
          gender: jobData.gender,
          deadline: formattedDeadline,
          minSalary: jobData.minSalary,
          maxSalary: jobData.maxSalary,

          salaryType: jobData.salaryType,

          city: jobData.cityid,
          localArea: jobData.localareaid,
          profession: jobData.requiredprofessionid,
          jobtypeid: jobData.jobtypeid,
        });

        setUserId(jobData.UserId);

        if (jobData.cityId) {
          axios
            .get(
              `https://api.askamarlabor.com/serverapi/localareas/bycity?city=${jobData.cityId}`
            )
            .then((response) => {
              setLocalAreaOptions(response.data);
            })
            .catch((error) => {
              console.error("Error fetching local area data by city:", error);
            });
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    axios
      .get("https://api.askamarlabor.com/serverapi/jobtypes")
      .then((response) => {
        setJobTypeOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profession data:", error);
      });

    fetchJobData();
  }, []);

  const [selectedCity, setSelectedCity] = useState(formData.city);
  const [selectedLocalArea, setSelectedLocalArea] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");

  useEffect(() => {
    if (selectedCity) {
      axios
        .get(
          `https://api.askamarlabor.com/serverapi/localareas/bycity?city=${selectedCity}`
        )
        .then((response) => {
          setLocalAreaOptions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching local area data by city:", error);
        });
    }
  }, [selectedCity]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === "profileImage" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    const formDataForUpdate = new FormData();
    // Append the job details to the FormData object
    formDataForUpdate.append("Title", formData.Title);
    formDataForUpdate.append("Description", formData.Description);
    formDataForUpdate.append("experience", formData.experience);
    formDataForUpdate.append("gender", formData.gender);
    formDataForUpdate.append("deadline", formData.deadline);
    formDataForUpdate.append("minSalary", formData.minSalary);
    formDataForUpdate.append("maxSalary", formData.maxSalary);
    formDataForUpdate.append("salaryType", formData.salaryType);

    // Append the location and profession data
    formDataForUpdate.append("CityId", formData.city);
    formDataForUpdate.append("localareaid", formData.localArea);
    formDataForUpdate.append("ProfessionId", formData.profession);

    // Append the job type data
    formDataForUpdate.append("jobtypeid", formData.jobtypeid);

    try {
      const response = await axios.put(
        `https://api.askamarlabor.com/serverapi/jobs/bybroker/${id}`,
        formDataForUpdate,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 200) {
        // Handle success, e.g., display a success message to the user
        toast.success("Job updated successfully!", {
          position: toast.POSITION.TOP_CENTER,
        });

        // Delay for 2 seconds before redirecting to the updated profile page
        setTimeout(() => {
          navigate(`/myjobs`);
        }, 2000); // 2000 milliseconds (2 seconds)
      } else {
        console.error(
          "Error updating job. Server responded with status:",
          response.status
        );
        // Handle the specific server response status code as needed
      }
    } catch (error) {
      console.error("Error updating job:", error);

      if (error.response) {
        // The request was made, but the server responded with a non-2xx status code
        console.error("Server responded with status:", error.response.status);
        console.error("Server response data:", error.response.data);

        // Handle specific error cases based on the status code
        if (error.response.status === 404) {
          // Handle 404 Not Found error
        } else if (error.response.status === 500) {
          // Handle 500 Internal Server Error
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error(
          "No response received from the server. Check server availability."
        );
      } else {
        // Something else happened while setting up the request
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  if (userId === authState.id) {
    return (
      <div>
        <TopBar />

        <div className="px-4 md:px-16">
          {" "}
          <>
            <p className="text-gray-700 text-3xl mb-16 font-bold mt-10">
              Update Profile
            </p>

            <form onSubmit={handleSubmit}>
              <div className="grid lg:grid-cols-2 gap-10 bg-white mb-10  p-9 rounded-lg">
                <div className="grid col-span-full">
                  <p className="text-black font-semibold mb-4">Job Title</p>

                  <input
                    type="text"
                    name="Title"
                    value={formData.Title}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid col-span-full">
                  <p className="text-black font-semibold mb-4">
                    Job description
                  </p>

                  <textarea
                    className="textarea textarea-info h-60 bg-secondary text-black"
                    placeholder=""
                    name="Description"
                    value={formData.Description}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">
                    Required profession
                  </p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.profession}
                    name="profession"
                    onChange={(e) => {
                      setSelectedProfession(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value="">Select a profession</option>
                    {professionOptions.map((profession) => (
                      <option key={profession.id} value={profession.id}>
                        {profession.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Job type</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.jobtypeid}
                    name="jobtypeid"
                    onChange={(e) => {
                      setSelectedJobType(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value="">Select job type</option>
                    {jobtypeOptions.map((jobType) => (
                      <option key={jobType.id} value={jobType.id}>
                        {jobType.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">
                    Experience (year)
                  </p>

                  <input
                    type="number"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    placeholder=""
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Gender</p>

                  <select
                    name="gender"
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">
                    Application deadline date
                  </p>
                  <input
                    type="date"
                    name="deadline"
                    value={formData.deadline}
                    onChange={handleChange}
                    placeholder=""
                    className="input input-bordered input-info w-full bg-secondary text-black"
                    required
                  />
                </div>

                <div className="grid">
                  <p className="text-black font-semibold mb-4">City</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.city}
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                      handleChange(e); // Call the handleChange function
                    }}
                    name="city"
                    required
                  >
                    <option value="">Select a city</option>
                    {cityOptions.map((city) => (
                      <option key={city.id} value={city.id}>
                        {" "}
                        {/* Updated this line */}
                        {city.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Local Area</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    name="localArea"
                    value={formData.localArea}
                    onChange={(e) => {
                      setSelectedLocalArea(e.target.value);
                      handleChange(e);
                    }}
                    required
                  >
                    <option value="">Select a local area</option>
                    {localAreaOptions.map((localArea) => (
                      <option key={localArea.id} value={localArea.id}>
                        {localArea.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Salary type</p>

                  <select
                    className="select select-info bg-secondary text-black w-full"
                    value={formData.salaryType}
                    onChange={handleChange}
                    name="salaryType"
                    required
                  >
                    <option>Select salary type</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Hourly">Hourly</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Daily">Daily</option>
                  </select>
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Min. salary</p>

                  <input
                    type="number"
                    name="minSalary"
                    value={formData.minSalary}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
                <div className="grid">
                  <p className="text-black font-semibold mb-4">Max. salary</p>

                  <input
                    type="number"
                    name="maxSalary"
                    value={formData.maxSalary}
                    onChange={handleChange}
                    className="input input-bordered input-info w-full  bg-secondary text-black"
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-outline border-primary hover:text-primary hover:bg-white bg-primary text-white mb-10"
              >
                Update Job
              </button>
            </form>
          </>
        </div>
        <ToastContainer />
      </div>
    );
  }
};

export default UpdateJob;
