import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import { BsChevronBarRight, BsChevronBarLeft } from "react-icons/bs";
import { filterProps, motion } from "framer-motion";
import { Link } from "react-router-dom";

import JobCard from "../components/JobCard";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { AiOutlineSearch } from "react-icons/ai";
import DummyLocation from "../components/DummyLocation";
import JobsDummyData from "../components/JobsDummyData";
import TopBar from "../components/TopBar";
import { AuthContext } from "../helpers/AuthContext";

import { CiLocationOn } from "react-icons/ci";
import { BsBriefcaseFill, BsFillFilterSquareFill } from "react-icons/bs";

import axios from "axios";

const Jobs = () => {
  const { authState } = useContext(AuthContext);
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(0);
  const [displayedJobs, setDisplayedJobs] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [searchProfession, setSearchProfession] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [filterOn, setFilterOn] = useState(false);

  const filterButton = () => {
    setFilterOn(!filterOn);
  };

  const [listOfJobs, setListOfJobs] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/jobs/all", {})
      .then((response) => {
        setListOfJobs(response.data);
      });
  }, []);

  const [cityOptions, setCityOptions] = useState([]);
  const [localAreaOptions, setLocalAreaOptions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [jobtypeOptions, setJobTypeOptions] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/cities")
      .then((response) => {
        setCityOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching city data:", error);
      });

    axios
      .get("https://api.askamarlabor.com/serverapi/professions")
      .then((response) => {
        setProfessionOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profession data:", error);
      });
    axios
      .get("https://api.askamarlabor.com/serverapi/localareas")
      .then((response) => {
        setLocalAreaOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching local area data:", error);
      });

    axios
      .get("https://api.askamarlabor.com/serverapi/jobtypes")
      .then((response) => {
        setJobTypeOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profession data:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCity) {
      axios
        .get(
          `https://api.askamarlabor.com/serverapi/localareas/bycity?city=${selectedCity}`
        )
        .then((response) => {
          setLocalAreaOptions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching local area data by city:", error);
        });
    }
  }, [selectedCity]);

  useEffect(() => {
    // Calculate the start and end index for the current page
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Get the workers data for the current page
    const workersSlice = listOfJobs.slice(startIndex, endIndex);
    setDisplayedJobs(workersSlice);
  }, [currentPage]);
  const filteredJobs = listOfJobs.filter((job) => {
    const titleMatches = job.Title.toLowerCase().includes(
      searchTitle.toLowerCase()
    );
    const locationMatches =
      !selectedLocation ||
      job.LocalArea.name.toLowerCase() === selectedLocation.toLowerCase();

    const cityMatches =
      !selectedCity || parseInt(job.cityid) === parseInt(selectedCity);

    const professionMatches =
      !searchProfession ||
      job.Profession.Name.toLowerCase().includes(
        searchProfession.toLowerCase()
      );
    const jobTypeMatches =
      !selectedJobType ||
      job.JobType.Name.toLowerCase() === selectedJobType.toLowerCase();
    const experienceMatches =
      !selectedExperience || job.experience >= selectedExperience;

    return (
      titleMatches &&
      cityMatches &&
      locationMatches &&
      professionMatches &&
      jobTypeMatches &&
      experienceMatches
    );
  });

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearchTitleChange = (event) => {
    setSearchTitle(event.target.value);
  };

  const handleProffesionChange = (event) => {
    setSearchProfession(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleNameSearch = (event) => {
    setSearchProfession(event.target.value);
  };

  const handleJobTypeChange = (event) => {
    setSelectedJobType(event.target.value);
  };

  const handleExperienceChange = (event) => {
    setSelectedExperience(event.target.value);
  };

  const pageCount = Math.ceil(filteredJobs.length / itemsPerPage);
  const currentJobs = filteredJobs.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const paginationVariants = {};

  return (
    <div>
      {authState.status ? <TopBar /> : <Navbar />}
      <h1 className="bg-white text-center text-4xl text-black py-5 mb-5">
        Jobs
      </h1>
      <div className="text-primary px-20 mb-5 lg:hidden ">
        <button onClick={filterButton} className="flex gap-1 items-center">
          <BsFillFilterSquareFill size={20} /> Filter
        </button>
      </div>
      <div className="flex lg:px-7 gap-8">
        <aside
          className={
            filterOn
              ? "bg-white p-8 text-black rounded-lg text-lg flex flex-col gap-3 lg:w-1/3 absolute lg:relative h-fit lg:block"
              : "hidden bg-white p-8 text-black rounded-lg text-lg flex flex-col gap-3 lg:w-1/3 absolute lg:relative h-fit lg:block"
          }
        >
          <h1>Search by Keyword</h1>
          <div className="relative mb-4 md:mr-2 flex-1 bg-secondary text-slate-700 rounded-lg ">
            <input
              value={searchTitle}
              onChange={handleSearchTitleChange}
              type="text"
              className="pl-10 pr-4 py-2 w-full rounded-lg bg-secondary focus:outline-none focus:shadow-outline"
              placeholder="Job title"
            />
            <div className="absolute top-0 left-0 px-3 py-3">
              <AiOutlineSearch size={17} />
            </div>
          </div>
          <h1>City</h1>
          <div className="relative mb-4 md:mr-2 flex-1 bg-secondary rounded-lg pr-3">
            <select
              className="pl-10 pr-4 py-2 w-full rounded-lg bg-secondary focus:outline-none focus:shadow-outline"
              onChange={handleCityChange}
            >
              <option value="" disabled>
                Select Location
              </option>
              <option value="">All Cities</option>
              {cityOptions.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.Name}
                </option>
              ))}
            </select>
            <div className="absolute top-0 left-0 px-3 py-3">
              <CiLocationOn size={17} />
            </div>
          </div>

          <h1>Location</h1>
          <div className="relative mb-4 md:mr-2 flex-1 bg-secondary rounded-lg pr-3">
            <select
              className="pl-10 pr-4 py-2 w-full rounded-lg bg-secondary focus:outline-none focus:shadow-outline"
              onChange={handleLocationChange}
            >
              <option value="" disabled>
                Select Location
              </option>
              <option value="">All Locations</option>
              {localAreaOptions.map((location) => (
                <option key={location.id} value={location.name}>
                  {location.name}
                </option>
              ))}
            </select>
            <div className="absolute top-0 left-0 px-3 py-3">
              <CiLocationOn size={17} />
            </div>
          </div>
          <h1>Profession</h1>
          <div className="relative mb-4 md:mr-2 flex-1 bg-secondary text-slate-700 rounded-lg">
            <select
              className="pl-10 pr-4 py-2 w-full rounded-lg bg-secondary focus:outline-none focus:shadow-outline"
              name="profession"
              onChange={handleProffesionChange}
            >
              <option value="">All professions</option>
              {professionOptions.map((profession) => (
                <option key={profession.id} value={profession.Name}>
                  {profession.Name}
                </option>
              ))}
            </select>
            <div className="absolute top-0 left-0 px-3 py-3">
              <BsBriefcaseFill size={17} />
            </div>
          </div>
          <h1>Job type</h1>
          <div className="relative mb-4 md:mr-2 flex-1 bg-secondary text-slate-700 rounded-lg pr-3">
            <select
              className="select select-info bg-secondary text-black w-full"
              onChange={handleJobTypeChange}
            >
              <option value="">All job types</option>
              {jobtypeOptions.map((jobType) => (
                <option key={jobType.id} value={jobType.Name}>
                  {jobType.Name}
                </option>
              ))}
            </select>
          </div>
          <h1>Experience (years)</h1>
          <div className="relative mb-4 md:mr-2 flex-1 bg-secondary text-slate-700 rounded-lg">
            <input
              type="number"
              className="pl-5 pr-4 py-2 w-full rounded-lg bg-secondary focus:outline-none focus:shadow-outline"
              placeholder="Years"
              onChange={handleExperienceChange}
            />
          </div>
        </aside>

        <div className="container mx-auto px-4 sm:px-6 lg:px-8 mb-6">
          {currentJobs.length > 0 ? (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 px-8 gap-5">
                {currentJobs.map((job) => (
                  <Link to={`/job/${job.id}`} key={job.id}>
                    <JobCard details={job} />
                  </Link>
                ))}
              </div>

              <motion.div
                variants={paginationVariants}
                initial="hidden"
                animate="visible"
                className="text-black"
              >
                <ReactPaginate
                  breakLabel={<span className="mr-4">...</span>}
                  nextLabel={
                    <span className="w-10 h-10 flex items-center justify-center bg-primary text-white rounded-md">
                      <BsChevronBarRight />
                    </span>
                  }
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  previousLabel={
                    <span className="w-10 h-10 flex items-center justify-center bg-primary text-white rounded-md mr-4">
                      <BsChevronBarLeft />
                    </span>
                  }
                  containerClassName="flex items-center justify-center mt-8 mb-4"
                  pageClassName="block border border-solid border-slate-400 hover:bg-white hover:text-primary w-10 h-10 flex items-center justify-center rounded-md mr-4"
                  activeClassName="bg-primary text-white"
                />
              </motion.div>
            </div>
          ) : (
            <p className="text-center text-2xl text-red-900">No jobs found.</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Jobs;
