import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import { BsChevronBarRight, BsChevronBarLeft } from "react-icons/bs";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";

import JobSeekerCard from "../components/JobSeekerCard";
import WorkersDummyData from "../components/WorkersDummyData";
import { AiOutlineSearch } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import DummyLocation from "../components/DummyLocation";
import TopBar from "../components/TopBar";
import { AuthContext } from "../helpers/AuthContext";

const Workers = () => {
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(0);
  const [displayedWorkers, setDisplayedWorkers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchName, setsearchName] = useState("");
  const { authState } = useContext(AuthContext);

  const [listOfWorkers, setListOfWorkers] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/profile/all", {})
      .then((response) => {
        setListOfWorkers(response.data);
      });
  }, []);

  const [cityOptions, setCityOptions] = useState([]);
  const [localAreaOptions, setLocalAreaOptions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [jobtypeOptions, setJobTypeOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    axios
      .get("https://api.askamarlabor.com/serverapi/cities")
      .then((response) => {
        setCityOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching city data:", error);
      });

    axios
      .get("https://api.askamarlabor.com/serverapi/professions")
      .then((response) => {
        setProfessionOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profession data:", error);
      });
    axios
      .get("https://api.askamarlabor.com/serverapi/localareas")
      .then((response) => {
        setLocalAreaOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching local area data:", error);
      });

    axios
      .get("https://api.askamarlabor.com/serverapi/jobtypes")
      .then((response) => {
        setJobTypeOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profession data:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCity) {
      axios
        .get(
          `https://api.askamarlabor.com/serverapi/localareas/bycity?city=${selectedCity}`
        )
        .then((response) => {
          setLocalAreaOptions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching local area data by city:", error);
        });
    }
  }, [selectedCity]);

  useEffect(() => {
    // Calculate the start and end index for the current page
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Get the workers data for the current page
    const workersSlice = listOfWorkers.slice(startIndex, endIndex);
    setDisplayedWorkers(listOfWorkers);
  }, [currentPage]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleNameSearch = (event) => {
    setsearchName(event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const filteredWorkers = listOfWorkers.filter((worker) => {
    const nameMatches =
      worker.Profession && worker.Profession.Name
        ? worker.Profession.Name.toLowerCase().includes(
            searchTerm.toLowerCase()
          )
        : false;
        const locationMatches =
        !selectedLocation ||
        (worker.LocalArea && worker.LocalArea.name.toLowerCase() === selectedLocation.toLowerCase());
      const keywordMatches =
      !searchName ||
      worker.fullName.toLowerCase().includes(searchName.toLowerCase());
    const cityMatches =
      !selectedCity || parseInt(worker.cityid) === parseInt(selectedCity);

    return nameMatches && locationMatches && keywordMatches && cityMatches;
  });

  const pageCount = Math.ceil(filteredWorkers.length / itemsPerPage);
  const currentWorkers = filteredWorkers.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const paginationVariants = {};

  return (
    <div>
      {authState.status ? <TopBar /> : <Navbar />}

      <div className="px-8 md:px-0 w-full max-w-lg lg:max-w-xl flex flex-col md:flex-row gap-2 mx-auto my-10">
        <div className="relative mb-4 md:mr-2 flex-1">
          <select
            className="pl-10 pr-4 py-2 w-full rounded-lg bg-white focus:outline-none focus:shadow-outline"
            name="profession"
            onChange={handleSearchInputChange}
          >
            <option value="">All professions</option>
            {professionOptions.map((profession) => (
              <option key={profession.id} value={profession.Name}>
                {profession.Name}
              </option>
            ))}
          </select>
          <div className="absolute top-0 left-0 px-3 py-3">
            <AiOutlineSearch size={17} />
          </div>
        </div>
        <div className="relative mb-4 md:mr-2 flex-1">
          <input
            type="text"
            value={searchName}
            onChange={handleNameSearch}
            className="pl-6 pr-4 py-2 w-full rounded-lg bg-white focus:outline-none focus:shadow-outline"
            placeholder="Name"
          />
        </div>
        <div className="relative mb-4 md:mr-2 flex-1">
          <select
            className="pl-6 pr-4 py-2 w-full rounded-lg bg-white focus:outline-none focus:shadow-outline"
            onChange={handleCityChange}
          >
            <option value="">All Cities</option>
            {cityOptions.map((location) => (
              <option key={location.id} value={location.id}>
                {location.Name}
              </option>
            ))}
          </select>
        </div>

        <div className="relative mb-4 md:mr-2 flex-1">
          <select
            className="pl-6 pr-4 py-2 w-full rounded-lg bg-white focus:outline-none focus:shadow-outline"
            onChange={handleLocationChange}
          >
            <option value="">All Locations</option>
            {localAreaOptions.map((location) => (
              <option key={location.id} value={location.name}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 mb-6">
        {currentWorkers.length > 0 ? (
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-8 gap-5">
              {currentWorkers.map((worker) => (
                <Link to={`/worker/${worker.id}`} key={worker.id}>
                  <JobSeekerCard details={worker} />
                </Link>
              ))}
            </div>

            <motion.div
              variants={paginationVariants}
              initial="hidden"
              animate="visible"
              className="text-black"
            >
              <ReactPaginate
                breakLabel={<span className="mr-4">...</span>}
                nextLabel={
                  <span className="w-10 h-10 flex items-center justify-center bg-primary text-white rounded-md">
                    <BsChevronBarRight />
                  </span>
                }
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel={
                  <span className="w-10 h-10 flex items-center justify-center bg-primary text-white rounded-md mr-4">
                    <BsChevronBarLeft />
                  </span>
                }
                containerClassName="flex items-center justify-center mt-8 mb-4"
                pageClassName="block border border-solid border-slate-400 hover:bg-white hover:text-primary w-10 h-10 flex items-center justify-center rounded-md mr-4"
                activeClassName="bg-primary text-white"
              />
            </motion.div>
          </div>
        ) : (
          <p className="text-center text-2xl text-red-900">No Workers found.</p>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Workers;
