import { Fragment } from "react";
import {
  Bars3CenterLeftIcon,
  PencilIcon,
  ChevronDownIcon,
  CreditCardIcon,
  Cog8ToothIcon,
  HomeIcon,
  NewspaperIcon,
} from "@heroicons/react/24/solid";
import { BellIcon, CheckIcon, PowerIcon } from "@heroicons/react/24/outline";
import { Menu, Transition, Popover } from "@headlessui/react";

import { useContext, useState, useEffect } from "react";
import axios from "axios";

import { AuthContext } from "../helpers/AuthContext";

export default function TopBar({ showNav, setShowNav }) {
  const { authState, setAuthState } = useContext(AuthContext);
  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState({ username: "", id: 0, status: false });
  };
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    if (authState.status && authState.usertype === "Employee") {
      axios
        .get("https://api.askamarlabor.com/serverapi/profile/profilepicture", {
          headers: { accessToken: localStorage.getItem("accessToken") },
        })
        .then((response) => {
          // Handle the response as needed
          const imagedata = response.data.profileImageName;
          setProfilePicture(imagedata);
        })
        .catch((error) => {
          console.error("Error fetching profile picture:", error);
        });
    } else if (
      authState.status &&
      (authState.usertype === "Employer" || authState.usertype === "Broker")
    ) {
      axios
        .get(
          "https://api.askamarlabor.com/serverapi/otherusersprofile/profilepicture",
          {
            headers: { accessToken: localStorage.getItem("accessToken") },
          }
        )
        .then((response) => {
          // Handle the response as needed
          const imagedata = response.data.profileImageName;
          setProfilePicture(imagedata);
        })
        .catch((error) => {
          console.error("Error fetching profile picture:", error);
        });
    }
  }, [authState.status]);

  return (
    <div
      className={`fixed w-full h-16 flex justify-between items-center transition-all duration-[400ms] bg-secondary ${
        showNav ? "pl-56" : ""
      }`}
    >
      <div className="pl-4 md:pl-16">
        <Bars3CenterLeftIcon
          className="h-8 w-8 text-gray-700 cursor-pointer"
          onClick={() => setShowNav(!showNav)}
        />
      </div>
      <div className="flex items-center pr-4 md:pr-16">
        {(authState.usertype === "Broker" ||
          authState.usertype === "Admin" ||
          authState.usertype === "Employer") && (
          <a
            href="/workers"
            className="mr-12 text-gray-700 mb-1 hover:text-primary hidden sm:block"
          >
            Workers
          </a>
        )}
        {(authState.usertype === "Broker" ||
          authState.usertype === "Admin" ||
          authState.usertype === "Employee") && (
          <a
            href="/jobs"
            className="mr-12 text-gray-700 mb-1 hover:text-primary hidden sm:block"
          >
            Jobs
          </a>
        )}
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center items-center">
              <picture>
                {authState.usertype === "Admin" ? (
                  <div className="flex items-start text-gray-700">
                    {authState.username}
                  </div>
                ) : (
                  <img
                    src={`https://api.askamarlabor.com/serverapi/images/${profilePicture}`}
                    className="rounded-full h-8 w-8 md:mr-2 border-2 border-white shadow-sm"
                    alt="profile picture"
                  />
                )}
              </picture>

              <ChevronDownIcon className=" h-4 w-4 text-gray-700" />
            </Menu.Button>
          </div>
          <Menu.Items className="absolute right-0 w-56 z-50 mt-2 origin-top-right bg-white rounded shadow-sm">
            <div className="p-1">
              {(authState.usertype === "Broker" ||
                authState.usertype === "Admin" ||
                authState.usertype === "Employee") && (
                <Menu.Item className="sm:hidden">
                  <a
                    href="/jobs"
                    className="flex hover:bg-primary hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center"
                  >
                    Jobs
                  </a>
                </Menu.Item>
              )}
              {(authState.usertype === "Broker" ||
                authState.usertype === "Admin" ||
                authState.usertype === "Employer") && (
                <Menu.Item className="sm:hidden">
                  <a
                    href="/workers"
                    className="flex hover:bg-primary hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center"
                  >
                    Workers
                  </a>
                </Menu.Item>
              )}
              <Menu.Item>
                <a
                  href="/"
                  className="flex hover:bg-primary hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center"
                >
                  <HomeIcon className="h-4 w-4 mr-2" />
                  Home
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  href={
                    authState.usertype === "Employee" ||
                    authState.usertype === "Employer" ||
                    authState.usertype === "Broker"
                      ? "/profile"
                      : authState.usertype === "Admin"
                      ? "/postjob"
                      : "/"
                  }
                  className="flex hover:bg-primary hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center"
                >
                  <NewspaperIcon className="h-4 w-4 mr-2" />
                  User Dashboard
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  onClick={logout}
                  href="/"
                  className="flex hover:bg-primary hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center"
                >
                  <PowerIcon className="h-4 w-4 mr-2" />
                  Log out
                </a>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
}
